import React, {MouseEventHandler} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import {ButtonGroup, ClickAwayListener, MenuItem, MenuList, Paper, Popper} from "@mui/material";
import Button from "@mui/material/Button";


export interface DropDownOption {
    name: string
    enabled: boolean
    icon: JSX.Element
    onClick: MouseEventHandler
}

export interface DropdownButtonProps {
    name: string;
    options: DropDownOption[];
    onClick: MouseEventHandler
    icon?: JSX.Element
    disabled?: boolean
}

export const DropdownButton = ({name, options, onClick, icon, disabled = false}: DropdownButtonProps) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);

    const handleMenuItemClick = (
        e: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: number,
    ) => {
        options[index].onClick(e)
        setOpen(false)
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };
    
    return <React.Fragment>
        <ButtonGroup
            variant="contained"
            ref={anchorRef}
            aria-label={`Connect or configure ${name}`}
        >
            <Button disabled={disabled} onClick={onClick} startIcon={icon}>{name}</Button>
            <Button
                disabled={options.length === 0}
                size="small"
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="menu"
                onClick={handleToggle}
            >
                <FontAwesomeIcon icon={faChevronDown}/>
            </Button>
        </ButtonGroup>
        <Popper
            sx={{
                zIndex: 1300,
            }}
            open={open}
            anchorEl={anchorRef.current}
            placement={"bottom-end"}
        >
            <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu" autoFocusItem>
                        {options.map((option, index) => (
                            <MenuItem
                                key={option.name}
                                disabled={!option.enabled}
                                onClick={(event) => handleMenuItemClick(event, index)}
                            >
                                {option.icon}
                                {option.name}
                            </MenuItem>
                        ))}
                    </MenuList>
                </ClickAwayListener>
            </Paper>
        </Popper>
    </React.Fragment>
};