import React, {useState} from "react";
import {IconButton, Menu, MenuItem} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

export interface Option {
    label: string
    onClick: () => void
    href?: string
}

const ITEM_HEIGHT = 48;

export const ButtonMenu = ({options, icon}: {
    options: Option[],
    icon?: IconProp
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <FontAwesomeIcon icon={icon ?? faChevronDown}/>

            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                slotProps={{
                    paper: {
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: '20ch',
                        },
                    },
                }}
            >
                {options.map((option, index) => (
                    <MenuItem {...(option.href ? {href: option.href} : {})}
                              key={index}
                              onClick={(e) => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                  option.onClick()
                                  handleClose()
                              }}>
                        {option.label}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}