import {OrganizationList, useAuth, useOrganization, useOrganizationList, useUser} from '@clerk/clerk-react';
import {Navigate, useLocation, useNavigate, useParams} from 'react-router-dom';
import React, {useEffect, useState} from "react";
import {CenteredElement} from "./CenteredElement";
import {CircularProgress} from "@mui/material";

export const ProtectedRoute = ({children}: { children: any }) => {
    const {isSignedIn, isLoaded} = useUser();
    const navigate = useNavigate();
    const location = useLocation();
    const {orgSlug} = useAuth()
    const {aid} = useParams<{ aid: string }>();
    const {isLoaded: orgLoaded} = useOrganization();
    const {userMemberships, setActive} = useOrganizationList({userMemberships: {infinite: true}});
    const [isMember, setIsMember] = useState(true)

    useEffect(() => {
        if (!isLoaded || !orgLoaded) {
            return;
        }
        // we use - to indicate that we should pick the active item
        if (aid === "-") {
            let targetAid = orgSlug
            if (!orgSlug) {
                return;
            }
            const pathname = location.pathname;
            navigate(pathname.replace("-", `${targetAid}`))
        }
        // try to find the org and switch to it
        else if (aid !== orgSlug) {
            const find = userMemberships.data?.find(userMembership => userMembership.organization.slug === aid);
            if (find) {
                setActive?.({organization: find.organization.slug})
            } else {
                setIsMember(false)
            }
        }
    }, [aid, orgSlug, userMemberships, isLoaded, orgLoaded, navigate, setActive, location.pathname]);

    if (!isLoaded || !orgLoaded) {
        return <CenteredElement><CircularProgress/></CenteredElement>
    }

    if (!isSignedIn) {
        return <Navigate to="/sign-in" replace/>;
    }

    if (!orgSlug) {
        return (
            <CenteredElement>
                <OrganizationList
                    hidePersonal={true}
                    afterSelectOrganizationUrl={(org) => {
                        setIsMember(true)
                        return `/h/${org.slug}/dashboard`
                    }}
                />
            </CenteredElement>
        )
    }

    if (!isMember) {
        return (
            <CenteredElement>
                <p>Not a member of the org: {aid}</p>
                <OrganizationList
                    hidePersonal={true}
                    afterSelectOrganizationUrl={(org) => {
                        setIsMember(true)
                        return `/h/${org.slug}/dashboard`
                    }}
                />
            </CenteredElement>
        )
    }

    return children;
};