import {Outlet, useNavigate} from 'react-router-dom'
import {ClerkProvider} from '@clerk/clerk-react'
import {AppConfig} from "../AppConfig";
import React from "react";
import {MultisessionAppSupport} from "@clerk/clerk-react/internal";
import {clerkTheme} from "../theme";

const PUBLISHABLE_KEY = AppConfig.clerk_key

if (!PUBLISHABLE_KEY) {
    throw new Error('Missing Publishable Key')
}

export default function RootLayout() {
    const navigate = useNavigate()

    return (
        <ClerkProvider
            routerPush={(to) => navigate(to)}
            routerReplace={(to) => navigate(to, {replace: true})}
            publishableKey={`${PUBLISHABLE_KEY}`}
            appearance={clerkTheme}
        >
            <MultisessionAppSupport>
                <main>
                    <Outlet/>
                </main>
            </MultisessionAppSupport>
        </ClerkProvider>
    )
}