import {useAuth} from '@clerk/clerk-react'
import {initializeApp} from 'firebase/app'
import {getAuth, connectAuthEmulator, signInWithCustomToken, UserCredential} from 'firebase/auth'
import {getFirestore, connectFirestoreEmulator} from 'firebase/firestore'
import {getStorage, connectStorageEmulator} from 'firebase/storage';
import {useEffect, useState} from "react";
import {AppConfig} from "./AppConfig";


// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDINhtrQxjr7yIK_Uyff2fnct8wt7DO7Us",
    authDomain: "aether-code-ai.firebaseapp.com",
    projectId: "aether-code-ai",
    storageBucket: "aether-code-ai.appspot.com",
    messagingSenderId: "625372183827",
    appId: "1:625372183827:web:b644b027c2660d8ea72ee6"
};

// Connect to your Firebase app
export const app = initializeApp(firebaseConfig)
// Connect to your Firestore database
export const db = getFirestore(app)
if (AppConfig.firebase.useEmulator) {
    connectFirestoreEmulator(db, AppConfig.firebase.fireStoreAddr, Number(AppConfig.firebase.fireStorePort))
}

// Connect to Firebase auth
export const auth = getAuth(app)
if (AppConfig.firebase.useEmulator) {
    connectAuthEmulator(auth, AppConfig.firebase.authUrl)
}
// Connect to Firebase Storage
export const storage = getStorage(app);
if (AppConfig.firebase.useEmulator) {
    connectStorageEmulator(storage, AppConfig.firebase.storageAddr, Number(AppConfig.firebase.storagePort))
}

export const useFirebase = () => {
    const {getToken, userId, orgId} = useAuth()
    const [fbToken, setFbToken] = useState<string | null>()
    const [userCredentials, setUserCredentials] = useState<UserCredential>()

    useEffect(() => {
        // we want to set the user creds to undefined as we are rotating the token
        setUserCredentials(undefined)

        getToken({template: 'integration_firebase'}).then(token => {
            setFbToken(token)

            signInWithCustomToken(auth, token || '').then(fbCred => {
                setUserCredentials(fbCred)
            })
        })
    }, [userId, orgId, getToken]);

    return {userCredentials, fbToken, db, storage}
}
