import React from 'react';
import {Box} from '@mui/material';

export const CenteredElement = ({children, height = '100vh'}: { children: any, height?: string }) => {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight={height}
        >
            {children}
        </Box>
    );
};
