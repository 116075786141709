import React from 'react';
import {
    Drawer,
    Box,
} from '@mui/material';

interface CustomDrawerProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

const CustomDrawer: React.FC<CustomDrawerProps> = ({ isOpen, onClose, children }) => {
    return (
        <Drawer
            anchor="right"
            open={isOpen}
            onClose={onClose}
        >
            <Box
                sx={{
                    width: 500,
                    padding: 2,
                }}
            >
                {children}
            </Box>
        </Drawer>
    );
};

export default CustomDrawer;