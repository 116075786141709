import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import {RouterProvider, createBrowserRouter, Navigate} from 'react-router-dom'
import RootLayout from './layouts/root-layout'
import HomeLayout from './layouts/home-layout'
import {SignInPage} from './routes/sign-in'
import {SignUpPage} from './routes/sign-up'
import reportWebVitals from "./reportWebVitals";
import {ProtectedRoute} from './components/ProtectedRoute'
import {ProjectLayout} from "./layouts/project-layout";
import {DashboardLayout} from "./layouts/dashboard-layout";
import {ThemeProvider} from "@mui/material";
import {theme} from "./theme";
import {ProviderConnect} from "./layouts/provider-connect";

const router = createBrowserRouter([
    {
        element: <RootLayout/>,
        children: [
            {path: '/', element: <Navigate to="/h" replace/>},
            {
                path: '/provider/connect/:provider',
                element: <ProviderConnect/>
            },
            {path: '/h', element: <Navigate to="/h/-/dashboard" replace/>},
            {path: '/sign-in/*', element: <SignInPage/>},
            {path: '/sign-up/*', element: <SignUpPage/>},
            {
                element: <ProtectedRoute> <HomeLayout/></ProtectedRoute>,
                path: '/h/:aid',
                children: [
                    {path: '/h/:aid/dashboard', element: <DashboardLayout/>},
                    {
                        path: '/h/:aid/projects',
                        element: <ProjectLayout/>,
                        children: [
                            {path: '/h/:aid/projects/:id', element: <div>phom</div>},
                        ]
                    }
                ]
            }
        ],
    },
])

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <RouterProvider router={router}/>
        </ThemeProvider>
    </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
