import * as React from 'react'
import {OrganizationSwitcher, useAuth, UserButton} from '@clerk/clerk-react'
import {Outlet, useNavigate} from 'react-router-dom'
import {useEffect} from "react";
import {AppBar, Avatar, Box, Button, Container, Toolbar, Typography} from '@mui/material';
import AddIcon from "@mui/icons-material/Add";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBook} from '@fortawesome/free-solid-svg-icons';


const DotIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor">
            <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z"/>
        </svg>
    )
}

const CustomPage = () => {
    return (
        <div>
            <h1>Custom Profile Page</h1>
            <p>This is the custom profile page</p>
        </div>
    )
}

export default function HomeLayout() {
    const {userId, isLoaded} = useAuth()
    const navigate = useNavigate()

    const handleNavigation = (event: React.MouseEvent<HTMLAnchorElement>, path: string) => {
        event.preventDefault();
        navigate(path);
    };

    useEffect(() => {
        if (isLoaded && !userId) {
            navigate('/sign-in')
        }
    }, [isLoaded, userId, navigate])

    if (!isLoaded) return (<div>'Loading...'</div>)


    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <Box display="flex" alignItems="center" sx={{flexGrow: 1}}>
                        <Button
                            color="inherit"
                            href="/h/-/dashboard"
                            onClick={(e) => handleNavigation(e as React.MouseEvent<HTMLAnchorElement>, '/h/-/dashboard')}
                            startIcon={
                                <Avatar
                                    src={`${process.env.PUBLIC_URL}/images/icon_200_200.png`}
                                    alt="AetherCodeAI"
                                />
                            }
                            sx={{textTransform: 'none'}}
                        >
                            <Typography variant="h6" component="div">
                                AetherCodeAI
                            </Typography>
                        </Button>

                        <OrganizationSwitcher
                            afterSelectOrganizationUrl={(org) => {
                                return `/h/${org.slug}/dashboard`
                            }}
                            afterSelectPersonalUrl={"/h/user/dashboard"}
                            hidePersonal={true}
                        >

                            <OrganizationSwitcher.OrganizationProfilePage
                                label="Terms"
                                labelIcon={<DotIcon/>}
                                url="terms"
                            >
                                <div>
                                    <h1>Custom Terms Page</h1>
                                    <p>This is the custom terms page</p>
                                </div>
                            </OrganizationSwitcher.OrganizationProfilePage>
                        </OrganizationSwitcher>
                        <Button
                            href="/h/-/dashboard"
                            onClick={(e) => handleNavigation(e as React.MouseEvent<HTMLAnchorElement>, '/h/-/dashboard')}
                            sx={{my: 2, color: 'white', display: 'block'}}
                        >
                            {"Dashboard"}
                        </Button>
                        <Button
                            href="/h/-/projects"
                            onClick={(e) => handleNavigation(e as React.MouseEvent<HTMLAnchorElement>, '/h/-/projects')}
                            sx={{my: 2, color: 'white', display: 'block'}}
                        >
                            {"Projects"}
                        </Button>
                    </Box>

                    <>
                        <UserButton showName={true}>

                            <UserButton.UserProfilePage label="Custom Page" url="custom" labelIcon={<DotIcon/>}>
                                <CustomPage/>
                            </UserButton.UserProfilePage>
                            <UserButton.MenuItems>
                                <UserButton.Action
                                    label="Open chat"
                                    labelIcon={<AddIcon/>}
                                    onClick={() => alert('init chat')}
                                />
                                <UserButton.Link href={"https://docs.aethercoding.ai"} label={"Docs"}
                                                 labelIcon={<FontAwesomeIcon icon={faBook}/>}/>
                            </UserButton.MenuItems>
                        </UserButton>
                    </>
                </Toolbar>
            </AppBar>
            <Container component="main" sx={{mt: 4}}>
                <Outlet/>
            </Container>`
        </>
    );
}