export const AppConfig = {
    clerk_key: process.env.REACT_APP_CLERK_PUBLISHABLE_KEY,
    apiHost: process.env.REACT_APP_API_HOST ?? "https://api.aethercoding.com",
    provider: {
        cookieName: `${process.env.REACT_APP_PROVIDER_OAUTH_COOKIE}`
    },
    firebase: {
        useEmulator:  process.env.REACT_APP_FIREBASE_EMULATOR ?? false,
        fireStorePort: process.env.REACT_APP_FIREBASE_FIRESTORE_EMULATOR_PORT ?? "8080",
        fireStoreAddr:process.env.REACT_APP_FIREBASE_FIRESTORE_EMULATOR_ADDR ?? "",
        authUrl: process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_URL ?? "",
        storagePort: process.env.REACT_APP_FIREBASE_STORAGE_EMULATOR_PORT ?? "9199",
        storageAddr:process.env.REACT_APP_FIREBASE_STORAGE_EMULATOR_ADDR ?? "",
    }
};
