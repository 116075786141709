import React from 'react';
import {
    Typography,
    IconButton,
    Box,
    Container,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

interface SectionHeaderProps {
    title: string;
    onAddClick?: () => void;
    hideButton?: boolean
}

const SectionHeaderComponent: React.FC<SectionHeaderProps> = ({ title, onAddClick = () => {}, hideButton = false }) => {
    return (
        <Box
            sx={{
                width: '100%',
                backgroundColor: 'background.paper',
                borderBottom: '1px solid',
                borderColor: 'divider',
            }}
        >
            <Container maxWidth={false}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: 2,
                    }}
                >
                    <Typography variant="h5" component="h2">
                        {title}
                    </Typography>
                    {!hideButton && (
                        <IconButton
                            size="small"
                            color="primary"
                            aria-label="add"
                            onClick={onAddClick}
                        >
                            <AddIcon />
                        </IconButton>
                    )}
                </Box>
            </Container>
        </Box>
    );
};

export default SectionHeaderComponent;