import {Box, Container} from "@mui/material";
import React from "react";
import SectionHeaderComponent from "../components/HeaderComponent";

export const DashboardLayout = () => {
    return (
        <Container maxWidth="lg">
            <Box display="flex" flexDirection="column" alignItems="center" my={4}>
                <SectionHeaderComponent
                    title="Dashboard"
                    hideButton={true}
                />
            </Box>
        </Container>
    )
}