import {ProviderButton} from "../../components/ProviderButton";
import {useClerkSWR, useDoc} from "../../utils/api";
import {useAuth} from "@clerk/clerk-react";
import {IProvider, IProviderConfig, IRepository} from "../../types/provider";
import {CenteredElement} from "../../components/CenteredElement";
import {
    Avatar,
    CircularProgress,
    Container,
    List,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    TextField
} from "@mui/material";
import React, {useState} from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import {AppConfig} from "../../AppConfig";


const gitlab = {
    id: "gitlab",
    type: "gitlab",
    name: "GitLab",
    url: "https://gitlab.com",
    client_id: "e7306b997479bf875986e5f8dbb64e49f169537c4f100b40319adcc268dd703d",
    scopes: ["api"]
}


function RepoList({repos, onClick}: { repos?: IRepository[], onClick?: (repo: IRepository) => void }) {
    return (
        <>
            <List sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>
                {
                    (repos ?? []).map((repo) => {
                        return <ListItemButton onClick={() => {
                            onClick?.(repo)
                        }} key={repo.id}>
                            <ListItemAvatar>
                                <Avatar src={repo.avatar}/>
                            </ListItemAvatar>
                            <ListItemText primary={repo.name} secondary={repo.type}/>
                        </ListItemButton>
                    })
                }
            </List>
        </>
    );
}

function SelectProject({provider, onClick}: {
    provider: IProviderConfig | undefined,
    onClick?: (repo: IRepository) => void
}) {
    const [search, setSearch] = useState("")
    const {data, isLoading} = useClerkSWR<IRepository[]>(`/api/v1/provider/${gitlab.id}/search?q=${search}`);


    return (
        <Container>
            <Container>
                <Box>
                    <p>Select the {provider?.name} project to connect</p>
                </Box>
            </Container>

            <Container>
                <Box component="form" noValidate sx={{p: '2px 4px', display: 'flex', alignItems: 'center'}}>
                    <TextField fullWidth label="Search" id="search" onChange={(e) => {
                        setSearch(e.currentTarget.value)
                    }}/>
                </Box>
            </Container>
            {
                isLoading &&
                <Container><CenteredElement height={"20%"}><CircularProgress/></CenteredElement></Container>
            }
            {
                !isLoading && <RepoList repos={data} onClick={onClick}/>
            }
        </Container>
    );
}

function ConfirmAndValidate({provider, repo, onCreate, orgId}: {
    provider: IProviderConfig | undefined,
    repo: IRepository | undefined,
    onCreate: () => void,
    orgId?: string | null
}) {
    const {snapshot, isLoading} = useDoc<IProvider>(`orgs/${orgId}/projects/${provider?.id}-${repo?.id}`);
    const [creating, setCreating] = useState(false)

    return (
        <>
            <Container>
                <Container>
                    <Box>
                        <p>Confirm you want to connect the project from {provider?.name}</p>
                        <p>{repo?.name}</p>
                    </Box>
                </Container>
                {
                    isLoading &&
                    <Container><CenteredElement height={"20%"}><CircularProgress/></CenteredElement></Container>
                }
                {
                    !isLoading && snapshot && <p>Repo already connected.</p>
                }
                <Container>
                    <CenteredElement height={"100%"}>
                        {creating && <CircularProgress/>}
                        <Button variant="contained" color={"primary"} onClick={() => {
                            setCreating(true)
                            onCreate()
                        }} disabled={isLoading || !!snapshot || creating}>
                            Connect
                        </Button>
                    </CenteredElement>
                </Container>
            </Container>
        </>
    );
}

export const NewProjectForm = ({onComplete}: { onComplete: () => void }) => {
    const {userId, getToken, orgId} = useAuth()
    const {snapshot, isLoading} = useDoc<IProvider>(`users/${userId}/providers/gitlab`);
    const [provider, setProvider] = useState<IProviderConfig>()
    const [repo, setRepo] = useState<IRepository>()
    const [showBack, setShowBack] = useState(false)

    const steps = [
        {
            name: 'Select Provider',
            children: <div>
                <p>Select the provider to connect a repository</p>
                {
                    isLoading ?
                        <CenteredElement height={"100%"}><CircularProgress/></CenteredElement> :
                        <ProviderButton name={snapshot ? "Use GitLab" : "Connect GitLab"} config={gitlab}
                                        connected={snapshot} onClick={(provider) => {
                            setProvider(provider)
                            setShowBack(true)
                            handleNext()
                        }}/>
                }
            </div>
        },
        {
            name: 'Select Repository',
            children: <SelectProject provider={provider} onClick={(repo) => {
                setRepo(repo)
                handleNext()
            }}/>,
        },
        {
            name: 'Create Project',
            children: <ConfirmAndValidate orgId={orgId} provider={provider} repo={repo} onCreate={() => {
                getToken().then((token) => {
                    fetch(`${AppConfig.apiHost}/api/v1/provider/${provider?.id}/connect/${repo?.id}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'accept': 'application/json',
                            'content-type': 'application/json',
                        },
                        method: 'POST',
                    }).then(() => {
                        onComplete()
                    }).catch(console.log)
                })
            }}/>
        }
    ];

    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <Box sx={{width: '100%'}}>
            <Stepper activeStep={activeStep}>
                {steps.map((step, index) => {
                    return (
                        <Step key={index} >
                            <StepLabel >{step.name}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <React.Fragment>
                <Typography sx={{mt: 2, mb: 1}}>{steps[activeStep].name}</Typography>
                <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                    {steps[activeStep].children}
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                    {
                        showBack &&
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{mr: 1}}
                        >
                            Back
                        </Button>
                    }
                </Box>
            </React.Fragment>
        </Box>
    );
}
