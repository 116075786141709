import { createTheme } from '@mui/material/styles';

// A custom theme for this app
export const theme = createTheme();

export const clerkTheme = {
    variables: {
        colorPrimary: 'black',
        colorText: 'black'
    }
}
